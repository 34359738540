
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";

export default defineComponent({
  name: "modal-card",
  props: {
    image: String,
    modalId: String
  },
  data() {
    return {
      path: "",
      book_id: "",
      question_id: "",
      is_preview: "0",
      item: {},
      //v-modal
      single_option_no: -1, //单选题
      multiple_option_no_array: [], //多选题
      judge_answer: "", //判断题
      essay_content_answer: "", //问答题
      //data
      single_list: [],
      multiple_list: [],
      judge: {},
      fill_list: [],
      essay: {}
    };
  },
  methods: {
    /**
     * 初始化选项
     */
    initOptionNo() {
      this.single_option_no = -1;
      this.multiple_option_no_array = [];
      this.judge_answer = "";
      this.essay_content_answer = "";
    },

    /**
     * 获取试题明细
     */
    getQuestionDetail() {
      this.initOptionNo();
      let question_id = this.question_id;
      const data = {
        question_id: question_id
      };
      ApiService.post("Question/QuestionDetail", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.item = data.data.question; //赋值1
            if ((this.item as any).question_type == "00") {
              this.single_list = data.data.single_list;
              for (let i = 0; i < this.single_list.length; i++) {
                if ((this.single_list[i] as any).is_checked == true) {
                  this.single_option_no = (
                    this.single_list[i] as any
                  ).option_no;
                  break;
                }
              }
            } else if ((this.item as any).question_type == "01") {
              this.multiple_list = data.data.multiple_list;
              for (let i = 0; i < this.multiple_list.length; i++) {
                if ((this.multiple_list[i] as any).is_checked == true) {
                  (this.multiple_option_no_array as any).push(
                    (this.multiple_list[i] as any).option_no
                  );
                }
              }
            } else if ((this.item as any).question_type == "02") {
              this.judge = data.data.judge;
            } else if ((this.item as any).question_type == "03") {
              this.fill_list = data.data.fill_list;
            } else if ((this.item as any).question_type == "04") {
              this.essay = data.data.essay;
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 收藏
     */
    handleRemarkChange(e) {
      let book_id = this.book_id;
      let question_id = this.question_id;
      let is_mark = e.currentTarget.checked ? "1" : "0";
      const data = {
        book_id: book_id,
        question_id: question_id,
        is_mark: is_mark
      };
      ApiService.post("Exercise/QuestionMark", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 定义Bus
     */
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({
          path: "/exercise/search",
          query: { book_id: this.book_id, path: this.path }
        });
      });
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("试题明细", ["练习", "查找试题"]);
    setCurrentPageButton("back", true);
    this.initBus();
    this.path = this.$route.query.path as string;
    this.book_id = this.$route.query.book_id as string;
    this.question_id = this.$route.query.question_id as string;
    this.is_preview = "1"; //固定
    this.getQuestionDetail();
  }
});
